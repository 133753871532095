import React from "react"
import Layout from "../components/Layout"
import RocketB from "../images/rocket-bg.png"
import RocketF from "../images/rocket-fire.png"
import "animate.css"
// import { StaticImage } from "gatsby-plugin-image"

const Mission = () => {
  return (
    <div>
      <Layout>
        <section class="text-white w-full body-font pt-[5%] relative">
          {/* <StaticImage
            src="../images/bg-image.png"
            class="absolute"
            layout="fixed"
          /> */}
          <div class="container mx-auto flex px-10 md:flex-row flex-col justify-center items-center">
            <div class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center justify-center text-center animate__animated animate__jackInTheBox">
              <h1 class="title-font sm:text-4xl text-lg mb-4 font-medium font-quick text-white">
                “Create Tamil teaching resources in an interesting way! “
              </h1>
              <p class="mb-8 leading-relaxed text-lg font-quick text-white">
                Our motto is to create a world class gamified learning platform
                for global tamil community that are supported by robust
                information communications technology and reflect the diverse
                needs of tamil students as future citizens in a changing global
                society.
              </p>
            </div>
            <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 relative animate__animated animate__fadeIn animate__delay-1s">
              <img
                class="object-cover object-center rounded"
                alt="hero"
                src={RocketB}
              />
              <img
                class="w-[20%] h-[60%] absolute animate-rocket top-[5%] right-[38%]"
                alt="rock"
                src={RocketF}
              />
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default Mission
